import type { InstanceOptions, ModalOptions } from 'flowbite';
import { Modal } from 'flowbite';
export default (...args: any): void => {
    const options: ModalOptions = {
        placement: 'center',
        closable: true,
        backdropClasses: 'bg-black/30 dark:bg-black/50 fixed inset-0 z-40 modal-custom-backdrop',
    };

    for (const id of args) {
        const instanceOptions: InstanceOptions = {
            id,
            override: true,
        };
        const el = document.getElementById(id);
        const modal = new Modal(el, options, instanceOptions);
        document.querySelector('.modal-custom-backdrop')?.remove();
        modal.hide();
    }
};
